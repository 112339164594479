import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { SEOInterface } from "../interfaces/global";

export default function MetaTags() {
  const { t } = useTranslation();

  const getMetaInfo = (path: string): SEOInterface => {
    const key = `seo.${(path.split('/').filter(s => s != '').join('.')) || 'home'}`;
    const metaInfo = t(key, { returnObjects: true }) as SEOInterface;

    return metaInfo?.title && metaInfo?.description && metaInfo?.keywords
      ? metaInfo
      : {
        title: 'Enlight Agency',
        description: 'Brightening Ideas.',
        keywords: 'digital marketing, gestão redes sociais, website developer, developers, developer',
      };
  };

  const useSEO = (): SEOInterface => {
    const location = useLocation();
    return getMetaInfo(location.pathname);
  };

  const seo = useSEO();

  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:type" content="article" />
      <meta property="og:locale" content="pt_PT" />
    </Helmet>
  );
}
